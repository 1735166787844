body {
  // background-color: #f0e8dd;
  color: #1e2c35;
}

.circle {
  top: 0;
  left: 0;
  position: absolute;
  z-index: 9999;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  opacity: 0;
  transition: transform 0.08s linear;
  -webkit-backdrop-filter: invert(100%);
  backdrop-filter: invert(100%);
  pointer-events: none;
}

::-moz-selection {
  color: red;
  background: yellow;
}

::selection {
  color: red;
  background: yellow;
}
